
import { defineComponent, PropType } from "vue";
import { ProCons } from "../../types";
export default defineComponent({
  props: {
    items: {
      type: Object as PropType<ProCons>,
    },
  },
});
