import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-ab648728")
const _hoisted_1 = {
  key: 0,
  class: "title mb-2"
}
const _hoisted_2 = ["alt", "src"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["d-flex flex-wrap centerV tags", _ctx.type])
  }, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.title) + ":", 1))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createBlock(_component_router_link, {
        class: "tag me-2 me-md-4 mb-2 center",
        key: item,
        to: {
        name: item.routerPage === undefined ? _ctx.routerPage : item.routerPage,
        params: { slug: item.slug, ..._ctx.params },
      }
      }, {
        default: _withCtx(() => [
          (item.code && _ctx.params.category.includes('countries', 'country'))
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                width: "20",
                height: "20",
                class: "me-2",
                alt: item.code,
                src: _ctx.$getFileSource(`/images/flags/${item.code}.svg`)
              }, null, 8, _hoisted_2))
            : _createCommentVNode("", true),
          _createElementVNode("span", null, _toDisplayString(item.name), 1)
        ]),
        _: 2
      }, 1032, ["to"]))
    }), 128))
  ], 2))
}