import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_section_title = _resolveComponent("section-title")!
  const _component_card_casino_online = _resolveComponent("card-casino-online")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_section_title, {
      class: "widget-title",
      title: _ctx.title
    }, null, 8, ["title"]),
    _createVNode(_component_a_row, null, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
          return (_openBlock(), _createBlock(_component_a_col, _mergeProps({
            key: item,
            xl: _ctx.span,
            xs: 24
          }, _ctx.colExtraProps), {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default", {
                item: item,
                i: i
              }, () => [
                _createVNode(_component_card_casino_online, {
                  excerpt: '',
                  class: _normalizeClass({ 'mt-3': i !== 0 }),
                  title: item.title,
                  thumbnail: item.thumbnail,
                  mini: true,
                  offer: item.excerpt,
                  href: item.href,
                  description: ""
                }, null, 8, ["class", "title", "thumbnail", "offer", "href"])
              ], true)
            ]),
            _: 2
          }, 1040, ["xl"]))
        }), 128))
      ]),
      _: 3
    })
  ]))
}