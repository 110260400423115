
import CardCasinoOnline from "../CardCasinoOnline/CardCasinoOnline.vue";
import { defineComponent, PropType } from "vue";
import { Casino } from "../../types";
import SectionTitle from "../SectionTitle/SectionTitle.vue";

export type LayoutType = "grid" | "list";

export default defineComponent({
  components: { CardCasinoOnline, SectionTitle },
  props: {
    title: String,
    items: {
      type: Object as PropType<Casino>,
    },
    span: {
      type: Number,
      defalt: 24,
      required: false,
    },
    colExtraProps: {
      type: Object,
      required: false,
    },
  },
});
