import { post } from "../utilis/api/wrappers";
import { camelizeKeys } from "../utilis/api/helpers";
import { POST } from "../utilis/api/endpoints";
import { Casino, API_Comment, CommentCTX, Rating, ProCons } from "../../types";
import { ref, computed, ComputedRef } from "vue";
import { message } from "ant-design-vue";

export default function postLike(type: string = 'casino') {
  //   const casino = ref<Casino>({} as Casino);
  const loading = ref<boolean>(true);
  let endpoint = POST.LIKE_CASINO;
  if(type === 'casino') {
    endpoint = POST.LIKE_CASINO;
  } else if(type === 'bookmakers') {
    endpoint = POST.LIKE_BOOKMAKER;
  }
  const submitLike = (id: number) => {
    post(endpoint + '/' + id,{
      headers: { "Content-Type": "application/json" },
    }).then((result) => {
      if (result.status === 200) {
        loading.value = false;
        message.success("Only awesome people liked it. Now you are one of them!");
      } else {
        message.error("Your like could not be sent! Try again later.");
      }
    });
  };

  return {
    submitLike,
    loading,
  };
}
