import { get } from "../utilis/api/wrappers";
import { camelizeKeys } from "../utilis/api/helpers";
import { GET } from "../utilis/api/endpoints";
import { Casino, AcceptedCountries, Rating, ProCons, Tag } from "../../types";
import { ref, computed, ComputedRef } from "vue";


export default function (criteria: object, customPath?: string) {
  const casinos = ref<Casino[]>([]);
  const loading = ref<boolean>(true);
  
  const fetchCasinos = () : Promise<void | Casino> => {
    return get<Casino[]>(GET.CASINOS + (customPath === undefined ? '' : customPath), criteria).then((result) => {
      loading.value = false;
      casinos.value = camelizeKeys(result);
    });
  };

  fetchCasinos();

  return {
    casinos,
    fetchCasinos,
    loading,
  };
}
