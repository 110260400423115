
import { defineComponent, PropType } from "vue";
import { SocialLink, Thumbnail } from "../../types";
import Rating from "../Rating/Rating.vue";

export default defineComponent({
  components: { Rating },
  props: {
    id: {
      type: Number,
    },
    thumbnail: {
      type: Object as PropType<Thumbnail>,
    },
    title: {
      type: String,
    },
    accepts: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
    },
    likes: {
      type: Number,
      default: 0,
    },
    rating: {
      type: Number,
      default: 1,
    },
    socials: {
      type: Object as PropType<SocialLink>,
      required: false,
    },
  },
  data() {
    return {
      likeWrapper: this.likes,
      canLike: true,
    };
  },
  methods: {
    likeAction() {
      if (this.canLike) {
        this.canLike = false;
        this.likeWrapper += 1;
        return this.$emit("onLike");
      }
      return false;
    },
  },
  computed: {
    returnCurrentURL(): string {
      return window.location.href;
    },
  },
});
