
import { defineComponent, PropType } from "vue";

import { Tag } from "../../types";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: false,
    },
    items: {
      type: Object as PropType<Tag>,
    },
    params: {
      type: Object,
    },
    routerPage: {
      type: String,
    },
    type: {
      type: String as PropType<"rounded" | "square">,
      default: "rounded",
    },
  },
});
