
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    description: {
      type: String,
    },
    items: {
      type: Object as PropType<String[]>,
    },
  },
});
